import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Taylor Ivings | Software Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is ',
  name: 'Taylor Ivings.',
  subtitle: 'I am a software developer.',
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'tayprofile',
  paragraphOne:
    'I am a software developer hailing from Victoria, British Columbia. After several years in customer service, I decided it was time for a change in careers after realizing I was unfulfilled with my career prospects.',
  paragraphTwo:
    'After dabbling in both game development and web development on my own, I took the next step and completed the Lighthouse Labs web development bootcamp in early 2021.',
  paragraphThree: `I am currently looking for a developer position to further build upon the fundamentals I've learned and grow as a developer.`,
  resume: 'https://resume.creddle.io/resume/ges80zrxsd8', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'cryptofolio',
    title: 'Cryptofolio',
    info:
      'A cryptocurrency information application that allows the user to easy see their investments and get up to date information regarding their currencies.',
    info2: '',
    url: 'https://github.com/T-Ivings/cryptofolio',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'scheduler',
    title: 'Scheduler',
    info:
      'A single page application that can create, edit, and delete interviews between a variety of preset interviewers.',
    info2: '',
    url: 'https://github.com/T-Ivings/scheduler',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'idoit',
    title: 'I-Do-It!',
    info: `A smart to do list application, capable of taking a user's input, and returning a 'todo' with the correct category for the user's input (buy, read, eat and watch). `,
    info2: '',
    url: 'https://github.com/T-Ivings/SmartToDo',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'jungle',
    title: 'Jungle',
    info: 'A simple, e-commerce application.',
    info2: '',
    url: 'https://github.com/T-Ivings/jungle',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'tweeter',
    title: 'Tweeter',
    info: 'A simple, single-page Twitter clone application',
    info2: '',
    url: 'https://github.com/T-Ivings/tweeter',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Want to get in touch?',
  btn: 'Send me an email',
  email: 'ivingstaylor@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/taylor-ivings/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/T-Ivings',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
